import * as React from 'react';
import { Link } from 'gatsby';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import { useEffect, useState } from 'react';
import TestResult from "../../components/test-result";


const ReferrerPage = ({ location }) => {
  const [result, setResult] = useState(null);
  const [iframeId, setIFrameId] = useState(0);
  const [iframeHost, setIframeHost] = useState(null);

  const host = (location.host === process.env.GATSBY_MAIN_HOST ? process.env.GATSBY_SECONDARY_HOST : process.env.GATSBY_MAIN_HOST) ?? location.host;
  const protocol = (host && host.startsWith("localhost")) ? "http://" : "https://";

  useEffect(() => {
    let origin = protocol + host;
    window.addEventListener('message', ev => {
      if (ev.origin === origin && ev.data.type === "referrer-iframe") {
        setResult(ev.data.referrer === "")
      }
    });
    setIframeHost(origin)
  }, []);

  let runTest = () => {
    setResult(null);
    setIFrameId(iframeId + 1 % 2);
  };

  return (
    <Layout>
      <h1>Document.referrer</h1>
      <p>
        The <code>document.referrer</code> property returns the URI of the page that linked to this page.
      </p>

      <div className="flex mb-10">
        <div className="w-1/2">
          <h3>Test Case</h3>
          <TestResult result={result}>
            <div className="border-2 p-5">
              {iframeHost != null ? (<iframe key={iframeId} src={iframeHost + "/html/referrer-iframe.html" + location.search}/>) : null}
            </div>
          </TestResult>
          <p>You should <b>NOT</b> see the above text.</p>
        </div>
      </div>
      <button
        className="bg-emerald-600 text-white w-full mb-10"
        onClick={runTest}
      >
        Run test
      </button>

      <Link to="/">
        Go back
      </Link>
    </Layout>
  );
}

export const Head = () => <Seo title="Document.referrer"/>
export default ReferrerPage;
